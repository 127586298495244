<template>
  <div class="auth__sign-in">
    <h1>Welcome back!</h1>

    <Alert v-if="signInError" class="sign-in__alert" style-modifiers="warning">
      <span style=" font-weight: bold; color: darkred!important; ">Sign in failed!</span>
      {{ msgError }}
    </Alert>

    <div class="sign-in__form">
      <TextInput
        id="email"
        v-model="email"
        name="email"
        type="email"
        :icon="emailIcon"
        mode="auth"
        placeholder="Email"
        :validate="emailValidate"
      />

      <TextInput
        id="password"
        v-model="password"
        name="password"
        type="password"
        :icon="keyIcon"
        mode="auth"
        placeholder="Password"
        :validate="passwordValidate"
      />
    </div>

    <div class="sign-in__action">
      <Button
        style-modifiers="text"
        text="Forgot your password?"
        class="sign-in__forgot"
        @click="$emit('forgot-password')"
      />
      <Button
        style-modifiers="success"
        text="Sign in"
        class="sign-in__button"
        @click="loginClick"
        :loading="signInClick"
      />
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/UI-Kit/TextInput";
import Button from "@/components/UI-Kit/Button";
import { authIconSetup } from "@/setups/old/authIcon";
import { authFormValidateSetup, authSetup } from "@/setups/authSetup";
import { ref, inject } from "vue";
import Alert from "@/components/UI-Kit/Alert";
import {gsap} from 'gsap';

export default {
  name: "TheAuthSignInForm",
  components: { Button, TextInput, Alert },
  setup(props, { emit }) {
    const { keyIcon, emailIcon } = authIconSetup();
    const { email, password, login, validator } = authSetup();
    const { emailValidate, passwordValidate } = authFormValidateSetup();
    const msgError = ref("");

    const signInError = ref(false);
    const signInClick = ref(false);

    const loginClick = async function() {
      signInClick.value = true;
      try {
        await login();
        emit("login");
      } catch (err) {
        if (err.name === "ValidationError") {
          gsap.to('.text-input_error', {x:"+=12", duration: 0.3, yoyo:true, repeat:1})
        } else if (err.name === "Error") {
          signInError.value = true;
          msgError.value = err.response.data.message[0].messages[0].message
        }
      }
      signInClick.value = false;
    };

    return {
      email,
      password,
      loginClick,
      keyIcon,
      emailIcon,
      emailValidate,
      passwordValidate,
      signInError,
      signInClick,
      msgError
    };
  }
};
</script>

<style scoped lang="scss">
.auth__sign-in {
  padding: 32px 0;

  width: 100%;
  display: grid;
  grid-template-areas:
    "sign-in__welcome"
    "sign-in__alert"
    "sign-in__form"
    "sign-in__action";

  * {
    width: 100%;
  }

  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 12px;
    color: #000000;

    opacity: 0.87;
    text-align: left;
    grid-area: sign-in__welcome;
  }

  .sign-in__form {
    grid-area: sign-in__form;
    display: flex;
    flex-direction: column;
    .text-input {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    margin-top: 10px;
  }

  .sign-in__action {
    grid-area: sign-in__action;
    display: grid;
    grid-template-areas: "sign-in__forgot sign-in__button";
    grid-template-columns: 1fr 1fr;
    margin-top: 40px;
  }

  .sign-in__forgot {
    font-size: 13px;
    line-height: 15px;
    grid-area: sign-in__forgot;
    justify-self: left;
    width: 145px;
  }

  .sign-in__button {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    grid-area: sign-in__button;
    justify-self: right;
    width: 180px;
    height: 40px;
  }
  .sign-in__alert {
    grid-area: sign-in__alert;
    text-align: left;
    font-size: 13px;
    line-height: 150%;
    border-radius: 8px;
    margin-top: 16px;
    margin-bottom: 24px;
  }
}
</style>
