<template>
  <div class="flex">
    <div class="logo">
      <IconNew name="logo-signup-promo" class="cover-promo-logo"/>
    </div>

    <div class="title">
      <span>Your pathway to PhD completion</span>
    </div>

    <div class="description">
      <span>Manage your PhD online, set your tasks, meet your goals<br> and be fully supported Page X Page</span>
    </div>

    <ButtonNew mode="ghost" text="Learn More" size="l" class="button-promo" @click="openLink('https://pagexpage.com')"/>

  </div>
  <div class="line"/>
  <div class="flex">
    <div class="logo">
      <IconNew name="auth-promo-canvas" class="cover-promo-canvas"/>
    </div>
    <div class="title">
      <span>The PhD Research Canvas</span>
    </div>
    <ButtonNew mode="ghost" text="Get Unstuck" size="l" class="button-promo" @click="openLink('https://pagexpage.com/the-phd-model-canvas/')"/>


  </div>
</template>

<script>
import IconNew from "@/components/UI-Kit/IconNew";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
export default {
  name: "TheAuthSignInPromo",
  components: {ButtonNew, IconNew},
  setup () {
    const openLink = function (link) {
      window.open(link)
    }
    return {
      openLink
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: #000000;
}

.description {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;

  color: #13246E;
}

.cover-promo-canvas {
  width: 103px;
  height: 80px;
}

.cover-promo-logo {
  width: 36px;
  height: 34px;
}
.line {
  width: 64px;
  border: 1px solid #CCCFDB;
  margin: 30px auto 30px auto;
}
.flex {
  display: flex;
  flex-direction: column;
  div {
    margin-bottom: 24px;
  }
  margin: auto;
  justify-items: center;
}
.button-promo {
  width: 167px;
  margin: 0 auto;
}
</style>
