<template>
  <div class="auth__reset-password">
    <h1>Reset your password here</h1>
    <Alert v-if="alertShow" class="reset-password__alert">
      <span style=" font-weight: bold; color: #0CAC60!important; "
        >Password reset sent!</span
      >
      You'll receive an email if you are registered on our system.
    </Alert>
    <div class="reset-password__form">
      <TextInput
        id="email"
        v-model="email"
        name="email"
        type="email"
        :icon="emailIcon"
        mode="auth"
        placeholder="Email"
        class="reset-password__email"
        :validate="emailValidate"
      />
    </div>

    <div class="reset-password__action">
      <Button
        style-modifiers="success"
        text="Reset password"
        class="reset-password__button"
        @click="resetPasswordClick"
        :loading="resetPasswordLoad"
      />
    </div>
  </div>
</template>

<script>
import { authIconSetup } from "@/setups/old/authIcon";
import { authFormValidateSetup, authSetup } from "@/setups/authSetup";
import TextInput from "@/components/UI-Kit/TextInput";
import Button from "@/components/UI-Kit/Button";
import Alert from "@/components/UI-Kit/Alert";
import { ref } from "vue";
import {gsap} from "gsap";

export default {
  name: "TheAuthResetPasswordForm",
  components: { Alert, TextInput, Button },
  setup(props, { emit }) {
    const { emailIcon } = authIconSetup();
    const { email, forgotPassword } = authSetup();
    const { emailValidate } = authFormValidateSetup();
    const alertShow = ref(false);
    const resetPasswordLoad = ref(false)

    const resetPasswordClick = async function() {
      resetPasswordLoad.value = true
      try {
        await forgotPassword();
        alertShow.value = true;
        emit("reset-password");
      } catch (err) {
        if (err.name === "ValidationError") {
          gsap.to('.text-input_error', {x:"+=12", duration: 0.3, yoyo:true, repeat:1})
        }
      }
      resetPasswordLoad.value = false;
    };

    return {
      email,
      resetPasswordClick,
      emailIcon,
      emailValidate,
      alertShow,
      resetPasswordLoad
    };
  }
};
</script>

<style scoped lang="scss">
.auth__reset-password {
  padding: 32px 0;

  width: 100%;
  display: grid;
  grid-template-areas:
    "reset-password__title"
    "reset-password__alert"
    "reset-password__form"
    "reset-password__action";

  * {
    width: 100%;
  }

  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 21px;

    color: #000000;

    opacity: 0.87;
    text-align: left;
    grid-area: reset-password__title;
  }

  .reset-password__form {
    grid-area: reset-password__form;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-top: 10px;
  }

  .reset-password__action {
    grid-area: reset-password__action;
    display: grid;
    margin-top: 40px;
  }

  .reset-password__button {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    justify-self: right;
    width: 216px;
    height: 40px;
  }

  .reset-password__alert {
    grid-area: reset-password__alert;
    text-align: left;
    font-size: 13px;
    line-height: 150%;
    border-radius: 8px;
    margin-top: 16px;
    margin-bottom: 24px;
  }
}
</style>
