<template>
  <div class="auth__check-email">
    <h1>Check your mailbox</h1>
    <img
      :src="require('@/assets/images/sign-up-email.svg')"
      alt="Check your mailbox"
    />
    <p class="auth-blue-accent-text">
      We’ve sent an email with confirmation link <br />
      to <b>{{ email }}</b>
    </p>
  </div>
</template>

<script>
export default {
  name: "TheAuthCheckEmailMessage",
  props: {
    email: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "../assets/styles/color-scheme";

.auth-blue-accent-text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: $pxp-blue-added-color;
}

.auth__check-email {
  padding: 32px 0;

  display: grid;
  grid-template-areas:
    "check-email__title"
    "check-email__img"
    "check-email__text"
    "check-email__button";
  grid-gap: 32px;
  justify-items: center;

  h1 {
    grid-area: check-email__title;
    margin-top: 50px;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 21px;
  }

  img {
    grid-area: check-email__img;
    height: 64px;
    width: 64px;
  }

  p {
    grid-area: check-email__text;
  }

  .check-email__button {
    grid-area: check-email__button;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */

    text-align: center;

    color: #4973ab;
    margin-bottom: 50px;
  }
}
</style>
