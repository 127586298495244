<template>
  <div class="auth__sign-up">
    <h1>Create your account</h1>
    <Alert
      v-if="registrationStatus === 'error'"
      style-modifiers="warning"
      class="sign-up__alert"
    >
      <span style=" font-weight: bold; color: darkred!important; "
        >Sign up failed</span
      >
      {{msgError}}
    </Alert>
    <div class="sign-up__form">
      <TextInput
        id="user"
        v-model="userData.name"
        name="user"
        type="text"
        :icon="userIcon"
        mode="auth"
        placeholder="What’s your full name?"
        class="sign-up__user"
        :validate="fullNameValidate"
      />

      <TextInput
        id="email"
        v-model="email"
        name="email"
        type="email"
        :icon="emailIcon"
        mode="auth"
        placeholder="Email"
        class="sign-up__email"
        :validate="emailValidate"
      />

      <TextInput
        id="password"
        v-model="password"
        name="password"
        type="password"
        :icon="keyIcon"
        mode="auth"
        placeholder="Password"
        class="sign-up__password"
        :validate="passwordValidate"
      />

      <TextInput
        id="university"
        v-model="userData.university"
        name="university"
        type="text"
        :icon="universityIcon"
        mode="auth"
        placeholder="University name (optional)"
        class="sign-up__university"
      />
    </div>

    <div class="sign-up__action">
      <Button
        style-modifiers="success"
        text="Sign up"
        class="sign-up__button"
        @click="registrationClick"
        :loading="registrationStatus === 'load'"
        :ok="registrationStatus === 'ok'"
      />
    </div>
  </div>
</template>

<script>
import { authIconSetup } from "@/setups/old/authIcon";
import TextInput from "@/components/UI-Kit/TextInput";
import Button from "@/components/UI-Kit/Button";
import Alert from "@/components/UI-Kit/Alert";
import { authFormValidateSetup, authSetup } from "@/setups/authSetup";
import { ref } from "vue";
import { gsap, TweenMax } from "gsap";

export default {
  name: "TheAuthSignUpForm",
  components: { TextInput, Button, Alert },
  setup(props, { emit }) {
    const { keyIcon, emailIcon, universityIcon, userIcon } = authIconSetup();
    const { email, password, userData, registration } = authSetup();
    const { emailValidate, passwordValidate, fullNameValidate } = authFormValidateSetup();
    const registrationStatus = ref("");
    const msgError = ref("");

    const registrationClick = async function() {
      try {
        registrationStatus.value = "load";
        await registration();
        registrationStatus.value = "ok";
        emit("registration", email.value);
      } catch (err) {
        if (err.name === "ValidationError") {
          gsap.to('.text-input_error', {x:"+=12", duration: 0.3, yoyo:true, repeat:1})
          registrationStatus.value = ""
        } else if (err.name === "Error") {
          registrationStatus.value = "error"
          msgError.value = err.response.data.message[0].messages[0].message
        }
      }
    };

    return {
      keyIcon,
      emailIcon,
      universityIcon,
      userIcon,
      userData,
      password,
      email,
      registrationClick,
      registrationStatus,
      emailValidate, passwordValidate, fullNameValidate,
      msgError
    };
  }
};
</script>

<style scoped lang="scss">
.auth__sign-up {
  padding: 32px 0;
  width: 100%;
  display: grid;
  grid-template-areas:
    "sign-up__title"
    "sign-up__alert"
    "sign-up__form"
    "sign-up__action";

  * {
    width: 100%;
  }

  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 21px;

    color: #000000;

    opacity: 0.87;
    text-align: left;
    grid-area: sign-up__title;
  }

  .sign-up__form {
    grid-area: sign-up__form;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-top: 10px;
  }

  .sign-up__action {
    grid-area: sign-up__action;
    display: grid;
    margin-top: 40px;
  }

  .sign-up__button {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    grid-area: sign-up__button;
    justify-self: right;
    width: 180px;
    height: 40px;
  }

  .sign-up__alert {
    grid-area: sign-up__alert;
    text-align: left;
    font-size: 13px;
    line-height: 150%;
    border-radius: 8px;
    margin-top: 16px;
    margin-bottom: 24px;
  }
}
</style>
